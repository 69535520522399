.total-pro {
    background: url('../icon/allproduct.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.total-order {
    background: url('../icon/order-now.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.order-ship {
    background: url('../icon/cargo.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.order-out {
    background: url('../icon/delivery.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.complete-order {
    background: url('../icon/correct.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.cancel-order {
    background: url('../icon/cancel.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.add-pro {
    background: url('../icon/addproduct.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.return-rqst {
    background: url('../icon/product-return.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.cmt-return {
    background: url('../icon/completed.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.cancelled-return {
    background: url('../icon/return.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.newdtcat {
    background: url('../icon/folder.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}
.newdtsubcat {
    background: url('../icon/image-gallery.png') center center no-repeat;
    background-size: contain;
    height: 120px;
    width: 110px;
    margin: auto;
}

.wdth150 {
    width: 150px;
}

.wdth60ps {
    width: 60%;
}

.hgt40 {
    height: 40px;
}

.labeltext {
    font-size: 19px;
    font-weight: 600;
    color: gray;
    margin-bottom: 5px
}

.btn-file {
    position: relative;
    overflow: hidden;
    justify-content: center;
    display: flex;
    /* width: 60%; */
}

.productupbtn :hover {
    background-color: aquamarine;
    cursor: pointer;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 100%;
    font-size: 30px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.mybtn {
    border: 1px solid gainsboro;
    border-radius: 5px;
    background-color: green;
    color: white;
    padding: 10px;

}


.pruploadtellingboxchild {
    color: white;
    font-size: 16px;
    font-weight: 600;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    background: #000000bf;
    z-index: 10000;
}

/* .upimage {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px;
} */
.a_tag {
    text-decoration: none;
    border: 1px solid #dedede;
    margin: 10px;
    display: inline-flex;
    height: 160px;
    width: 140px;
    justify-content: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.modal_productmore {
    width: 100%;
    height: 422px;
    position: fixed;
    background-color: rgb(214, 214, 214);
    z-index: 1000;
    align-items: center;
    justify-content: center;
    display: flex;
}

.modal_productmorep {
    display: flex;
    justify-content: center;
}

/* citystore css */
.box78274hhdui {
    height: 150px;
    width: 150px;
    background-color: rgb(55, 54, 54);
    border: 1px solid black;
    font-weight: bold;
    margin: 5px;
    color: white;
    text-decoration: none;
}